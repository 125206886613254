import type { IBanner } from '~/types/movies';
import type { RouteLocationRaw } from 'vue-router';
import { createDurationFormatter } from '~/helpers/datetime';

const formatDuration = createDurationFormatter('ru-RU', 'short', ['hours', 'minutes'], 1, true, false);

export const useBanner = (banner: IBanner) => {
  const route = computed((): RouteLocationRaw => ({ name: 'movie-slug', params: { slug: banner.content.slug } }));
  const duration = computed(() => formatDuration((banner?.content?.duration ?? 0) * 1000));
  return {
    graphicTitle: banner?.content?.graphic_title,
    graphicTitleOriginal: banner?.content?.graphic_original_title,
    images: {
      desktop: banner.image,
      middle: banner.image_small,
      mobile: banner.mobile_image,
    },
    content: banner?.content,
    name: banner?.content?.name || '',
    text: banner?.content?.text,
    genre: banner?.content?.genres[0],
    minAge: banner?.content?.min_age,
    rating: {
      flex: banner?.content?.rating,
      kp: banner?.content?.kp,
      imdb: banner?.content?.imdb,
    },
    film: {
      year: banner?.content?.year,
      countries: banner?.content?.countries[0],
      duration: duration.value,
    },
    isFilm: banner?.content?.category === 'film',
    route,
  };
};
